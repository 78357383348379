import { render, staticRenderFns } from "./TagsTableHeader.vue?vue&type=template&id=0af20338&scoped=true&"
import script from "./TagsTableHeader.vue?vue&type=script&lang=ts&"
export * from "./TagsTableHeader.vue?vue&type=script&lang=ts&"
import style0 from "./TagsTableHeader.vue?vue&type=style&index=0&id=0af20338&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af20338",
  null
  
)

export default component.exports